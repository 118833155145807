<template>
  <div>
    <!--begin::BusinessAccountType-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 v-if="!edit_form" class="card-label">Business Account Types Add</h3>
          <h3 v-else class="card-label">Business Account Type Updates</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="mt-3 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span
                  class="v-btn__content"
                  @click="redirectToBusinessAccountTypeList()"
                >
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form
          class="form"
          novalidate="novalidate"
          id="kt_business_account_type_form"
        >
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" v-model="valid" lazy-validation>

              <v-row no-gutters>                
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="business_account_type.name"
                    label="Name"
                    :rules="nameRules"
                    required
                    dense
                    outlined
                    class="pl-2"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-radio-group v-model="business_account_type.status">
                  <v-col cols="12" sm="4">
                    <v-radio
                      
                      class="color-purple"
                      label="Active"
                      value="1"
                    ></v-radio>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-radio
                      
                      class="color-purple"
                      label="Inactive"
                      value="0"
                    ></v-radio>
                  </v-col>
                </v-radio-group>
              </v-row>

              <v-row fluid class="card-footer pl-3 pt-3">
                <v-btn
                  v-if="edit_form"
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  ref="kt_store_submit"
                  @click="validateEditForm"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Update
                </v-btn>
                <v-btn
                  v-else
                  :disabled="!valid || disable_btn"
                  color="success"
                  class="mr-4"
                  ref="kt_store_submit"
                  @click="validate"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Save
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::BusinessAccountType-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import {
  STORE_BUSINESS_ACCOUNT_TYPE,
  UPDATE_BUSINESS_ACCOUNT_TYPE
} from "@/core/services/store/businessaccounttype.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import EventBus from "@/core/services/store/event-bus";

export default {
  name: "businessAccountTypeCreate",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      business_account_type: {
        name: "",
        company_id: "",
        company_type: "",
        status: ""
      },
      company_users: "",
      company_list: [],
      valid: true,
      disable_btn: false,
      nameRules: [
        v => !!v || "Name is required",
        v => (v && v.length <= 25) || "Name must be less than 25 characters"
      ],
      edit_form: false,
      show1: true
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["companyUsers"]),
    
  },
  mounted() {
    let context = this;
    context.getCompanyUsers();
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "business-account-types/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.business_account_type) {
            context.business_account_type = result.data.business_account_type;
            context.business_account_type.status = result.data.business_account_type.status.toString();
          } else {
            context.$router.go(-1); //go back to list
            context.disable_btn = false;
            Swal.fire(result.data.error, "", "error");
          }
        },
        function(error) {
          context.disable_btn = false;
          Swal.fire("Error", "Business Account Type not found.", "error");
          context.$router.push({ name: "businessAccountTypeList" });
        }
      );
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Business Account Types" }]);

    const business_account_type_form = KTUtil.getById("kt_business_account_type_form");

    this.fv = formValidation(business_account_type_form, {});

    EventBus.$on("UPDATE_BUSINESS_ACCOUNT_TYPE_ERROR", function(payLoad) {
      context.$router.push({ name: "businessAccountTypeList" });
    });
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {       
    getCompanyUsers() {
      let context = this;
      this.$store.dispatch(GET_COMPANY).then(() => {
        if (this.company) {
          this.companies = this.company;

          this.companies.forEach(user => {
            this.company_list.push(user.name);
          });
        } else {
          axios({
            method: "post",
            url: "get-all-company",
            baseURL: process.env.VUE_APP_ACBACK_URL
          }).then(function(result) {
            console.log(result.data.company)
            if (result.data.company) {
              const companyData = result.data.company;
              companyData.forEach(comp => {
                context.company_list.push({
                  text: comp.name,
                  value: comp.id
                });
              });
            }
          });
        }
      });
    },
    updateBusinessAccountType() {
      this.$store
        .dispatch(UPDATE_BUSINESS_ACCOUNT_TYPE, this.business_account_type)
        .then(() => {
          this.$router.go(-1);
        });
    },
    redirectToBusinessAccountTypeList() {
      let context = this;
      context.$router.go(-1);
    },
    submitBusinessAccountType() {
      let context = this;
      this.$store
        .dispatch(STORE_BUSINESS_ACCOUNT_TYPE, this.business_account_type)
        .then(() => {
          context.disable_btn = false;
          this.$router.go(-1);
        });
    },
    validateEditForm() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.updateBusinessAccountType();
      } else {
        this.snackbar = true;
      }
    },
    validate() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.submitBusinessAccountType();
      } else {
        this.snackbar = true;
      }
    }
  }
};
</script>
